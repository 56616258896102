<template>
  <BModal
    id="success-modal"
    ref="modalRef"
    v-model="visible"
    size="md"
    body-class="p-0 position-static pb-0 "
    centered
    hide-footer
    v-bind="$props"
    header-class="p-0 mb-0"
    style="z-index: 1110"
    @hide="hide"
  >
    <div class="w-100">
      <div>
        <div
          class="greenTickCircle mx-auto"
          style="width: 88px; height: 88px; background-size: contain"
        ></div>
      </div>
      <div
        class="requestBottomBtnModal row justify-content-center px-lg-0 px-2"
      >
        <div class="w-100 text-center fz-18 fw-6 py-4">
          {{ modalText }}
        </div>
      </div>
    </div>
  </BModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useSuccessStore } from "~/stores/success";

export default defineComponent({
  name: "SuccessModal",
  components: {},
  props: {
    text: {
      type: String,
      default: "Are you sure you want to do this action?",
    },
  },
  emits: ["hide"],
  computed: {
    visible() {
      return useSuccessStore().displayModal;
    },
    modalText() {
      return useSuccessStore().modalText;
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
});
</script>

<style scoped></style>
