<template>
  <BModal
    id="error-modal"
    v-model="visible"
    size="lg"
    body-class="p-0 position-static pb-0"
    centered
    hide-footer
    no-stacking
    v-bind="$props"
    header-class="p-0 mb-lg-5 mb-2 "
    style="z-index: 10000"
    :title="titleText"
    title-class="h2 fw-4 colorRed fz-26"
    @hide="hide"
  >
    <div class="w-100">
      <div class="mx-auto col-8 text-center fz-20 fw-6 py-4">
        {{ errorMessage }}
      </div>
      <div
        class="requestBottomBtnModal row justify-content-center px-lg-0 px-2"
      >
        <div class="wrapBtn w-100 d-flex justify-content-center px-0 flex-wrap">
          <j-button class="w-100 mx-lg-3 mx-2 mt-3" text="ok" @click="hide" />
        </div>
      </div>
    </div>
  </BModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import JButton from "~/shared/ui/Button.vue";
import { useErrorsStore } from "~/stores/errors";

export default defineComponent({
  name: "ErrorModal",
  components: {
    JButton,
  },
  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },
  emits: ["remove", "hide"],
  data() {
    return {};
  },
  computed: {
    visible() {
      return useErrorsStore().displayModal;
    },
    errorMessage() {
      return useErrorsStore().modalText;
    },
    titleText() {
      return useErrorsStore().modalTitle;
    },
  },
  methods: {
    hide() {
      useErrorsStore().closeModal();
    },
  },
});
</script>

<style scoped></style>
