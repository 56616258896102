<template>
  <header class="mainHeader w-100 bg-white">
    <div
      class="wrapHeader container d-flex align-items-center justify-content-between flex-wrap w-100"
    >
      <nuxt-link to="/" class="logo-h d-table mx-md-auto mx-xl-0">
        <img src="~/assets/img/logo-h.svg" alt="" />
      </nuxt-link>
      <button
        class="hamburger btnNone position-relative hamburger--slider d-md-none d-block"
      >
        <span class="hamburger-box d-block">
          <span class="hamburger-inner d-block"></span>
        </span>
      </button>
      <div
        class="wrapMenu-h col-xl col-12 d-flex align-items-md-center flex-wrap flex-md-row flex-column justify-content-md-center px-md-0 px-3 py-md-0 py-4 mt-md-3 mt-xl-0"
      >
        <nav
          class="nav-h col-xl col-12 d-flex justify-content-center my-auto my-md-0"
        >
          <ul>
            <li>
              <a href="#" class="active">Home</a>
            </li>
            <li>
              <a href="#">Jobs</a>
            </li>
            <li>
              <a href="#">Courses</a>
            </li>
            <li>
              <a href="#">News</a>
            </li>
            <li>
              <a href="#">Useful</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul>
        </nav>
        <div
          v-if="loggedIn"
          class="wrapBtn-h d-flex align-items-center justify-content-center pt-xl-0 pt-md-3 mb-4 mb-md-0"
        >
          <a
            v-if="user"
            href="javascript:void(0)"
            class="personalAccount-h d-inline-flex align-items-center position-relative me-xl-4 me-3"
          >
            <span class="personalAccountIcon ic me-xl-4 me-3"></span>
            {{ user?.name }}
            <template v-if="user?.surname">
              <br />
              {{ user?.surname }}
            </template>
          </a>
          <j-button class="ms-3" text="Logout" @click="logout" />
        </div>
      </div>
      <!--      TODO: меню на мобиле работает по классу active на hamburger-->
    </div>
  </header>
</template>

<script lang="ts">
import JButton from "~/shared/ui/Button.vue";

export default defineComponent({
  name: "DefaultHeader",
  components: { JButton },
  async setup() {
    const { status, data, signOut } = await useAuth();

    const user = computed(() => data.value?.data || {});

    const loggedIn = computed(() => status.value === "authenticated");

    const logout = () => {
      signOut({ callbackUrl: "/login" });
    };

    return {
      loggedIn,
      user,
      logout,
    };
  },
});
</script>
