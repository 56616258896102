<template>
  <div class="w-100 d-flex flex-column mainWrapPage">
    <ErrorModal />
    <SuccessModal />
    <DefaultHeader />
    <div class="w-100 mainWrapPage px-0 d-flex  position-relative">
      <HRSidebar v-if="userIsHR" />
      <div class="mainPage mx-auto">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DefaultHeader from "~/widgets/Header.vue";
import HRSidebar from "~/widgets/Sidebar.vue";
import ErrorModal from "~/widgets/modals/ErrorModal.vue";
import SuccessModal from "~/widgets/modals/SuccessModal.vue";
import { useErrorsStore } from "~/stores/errors";

export default defineComponent({
  name: "DefaultLayout",
  components: { HRSidebar, DefaultHeader, ErrorModal, SuccessModal },
  async setup() {
    const { status } = await useAuth();

    const errorStore = useErrorsStore();

    const userIsHR = computed(() => status.value === "authenticated");
    // const userIsHR = computed(() => true);

    return {
      userIsHR,
      errorStore,
    };
  },
});
</script>
